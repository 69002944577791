@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


.hammersmith, body {
  font-family: "Hammersmith One", sans-serif;
  font-weight: 400;
  font-style: normal;
}